<template>
  <div>
    <UserFeedback :target="t('componentNames.multimedia')" class="end mr-0 mt-1"></UserFeedback>
    <div class="columns is-variable is-0">
      <div class="column is-7">
        <p class="mb-2">
          <b-button
            :loading="isLoading"
            @click="getMyFiles"
            icon-left="refresh"
            size="is-small"
          >{{t('multimedia.refreshList')}}</b-button>
        </p>
        <b-table
          :data="myFiles"
          striped
          hoverable
          :selected.sync="selectedImage"
          detailed
          detail-key="id"
        >
          <b-table-column sortable :custom-sort="sortByLp" :label="t('common.lp')" v-slot="props" width="20">{{ myFiles != null ? myFiles.indexOf(props.row) + 1 : props.index + 1}}</b-table-column>
          <b-table-column
            field="originalFileName"
            searchable
            sortable
            :label="t('common.fileName')"
            v-slot="props"
          >{{ props.row.originalFileName }}</b-table-column>
          <b-table-column
            field="description"
            searchable
            sortable
            :label="t('forms.description')"
            v-slot="props"
          >{{ props.row.description }}</b-table-column>
          <b-table-column field="tags" searchable sortable :label="t('forms.tags')" v-slot="props">
            <b-taglist>
              <b-tag v-for="tag in props.row.tags" :key="tag">
                {{
                tag
                }}
              </b-tag>
            </b-taglist>
          </b-table-column>
          <template slot="detail" slot-scope="props">
            <ImageEdit :imageId="props.row.id" @save="saveChange"></ImageEdit>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="package-variant" size="is-large"></b-icon>
                </p>
                <p>{{t('common.noData')}}</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
      <div class="column pl-5">
        <b-tabs type="is-boxed" expanded :animated="false">
          <b-tab-item :label="t('common.preview')">
            <template v-if="currentImage">
              <b-image :src="currentImage" ratio="16by9" class="mb-4">
                <template #placeholder>
                  <b-skeleton class="skeleton-placeholder" height="100%"></b-skeleton>
                </template>
              </b-image>
            </template>
            <template v-else>
              <section class>
                <div class="notification is-light content has-text-centered p-5">
                  <p class="pt-5">
                    <b-icon icon="camera-image" size="is-large"></b-icon>
                  </p>
                  <p class="pb-5">{{t('multimedia.noImage')}}</p>
                </div>
              </section>
            </template>
          </b-tab-item>
          <b-tab-item :label="t('multimedia.addNewImage')">
            <div class="pt-3">
              <ValidationObserver v-slot="{ invalid }">
                <form>
                  <b-field>
                    <ValidationProvider rules="required" v-slot="{ errors, valid }">
                      <b-field
                        :type="{
                          'is-danger': errors[0],
                          'is-success': valid,
                        }"
                        :message="t(errors[0])"
                      >
                        <b-upload
                          v-model="currentFile.upladedFile"
                          class="file-label"
                          accept="image/png, image/jpeg, image/gif"
                          required
                          expanded
                        >
                          <a class="button is-fullwidth">
                            <b-icon icon="upload"></b-icon>
                            <span v-if="currentFile.upladedFile">{{ currentFile.upladedFile.name }}</span>
                            <span v-else>{{t('multimedia.selectFile')}}</span>
                          </a>
                        </b-upload>
                      </b-field>
                    </ValidationProvider>
                  </b-field>

                  <b-field :label="t('forms.description')" label-position="on-border">
                    <b-input
                      maxlength="300"
                      v-model="currentFile.description"
                      :has-counter="false"
                      expanded
                    ></b-input>
                  </b-field>
                  <b-field :label="t('forms.tags')" label-position="on-border">
                    <b-taginput
                      maxlength="300"
                      v-model="currentFile.tags"
                      ellipsis
                      icon="plus"
                      :placeholder="t('common.add')"
                      type="is-primary"
                      :aria-close-label="t('common.remove')"
                      expanded
                    ></b-taginput>
                  </b-field>
                  <p class="has-text-centered">
                    <b-button
                      @click="sendFile"
                      :loading="isLoading"
                      :disabled="invalid"
                      icon-left="check"
                      type="is-primary"
                    >{{t('multimedia.sendFile')}}</b-button>
                  </p>
                </form>
              </ValidationObserver>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "../../store/config_actions";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageEdit from "./ImageEdit";
import UserFeedback from "@/components/feedbacks/UserFeedback";
import { SERVER } from '@/store/config_apis'

export default {
  name: "ImagesList",
  mixins: [CommonHelpers],
  components: {
    ValidationProvider,
    ValidationObserver,
    ImageEdit,
    UserFeedback,
  },

  props: {},

  data: function () {
    return {
      selectedImage: null,
      myFiles: [],
      currentFile: {
        description: "",
        type: 0,
        upladedFile: null,
        tags: [],
      },
      isLoading: false,
      currentImage: null,
      refreshTrigger: 0,
    };
  },

  mounted() {
    if (this.$store.state.media.myFiles) {
      this.myFiles = this.$store.state.media.myFiles;
    } else {
      this.getMyFiles();
    }
  },

  watch: {
    selectedImage(val) {
      if (val) {
        this.currentImage = `${SERVER}/api/MediaFile/File/${val.id}`;
      } else {
        this.currentImage = null;
      }
    },

    refreshTrigger() {
      this.getMyFiles();
    },
  },

  computed: {},

  methods: {
    sendFile() {
      this.isLoading = true;

      this.$store
        .dispatch(Action.MEDIA_UPLOAD, this.currentFile)
        .then(() => {
          this.mSuccessSnack(this.t('common.fileAdded'));
          this.getMyFiles();
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getMyFiles() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.MEDIA_GET_MY)
        .then((payload) => {
          this.myFiles = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    saveChange() {
      this.refreshTrigger++;
      this.currentImage = null;
    },

    sortByLp(first, second, isAsc){
        if(this.myFiles != null){
          let indexFirst = this.myFiles.indexOf(first);
          let indexSecond = this.myFiles.indexOf(second);
          if(isAsc){
            return indexSecond - indexFirst
          }else{
            return indexFirst- indexSecond
          }
        }else{
          return 0
        }
    }
  },
};
</script>

<style scoped>
div.end {
  float: right;
}
</style>