<template>
  <div class="home">
      <ImagesBase/>
  </div>
</template>

<script>

import ImagesBase from '@/components/image/ImageBase'

export default {
  name: 'Images',
  components: {
      ImagesBase
  }
}
</script>
