<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <b-tabs :animated="false">
      <b-tab-item :label="t('componentNames.multimedia')" icon="image-search">
        <ImagesList></ImagesList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import Commons from "@/mixins/commons";
import ImagesList from "./ImagesList"


export default {
  name: "ImageBase",
  mixins: [Commons],
  components: { 
      ImagesList
  },

  data: function () {
    return {
    };
  },

  mounted() {
  },

  methods: {

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
