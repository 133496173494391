<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <form>
        <div class="columns">
          <div class="column">
            <b-field>
              <b-field :label="t('forms.description')" label-position="on-border">
                <b-input
                  v-model="imageEdit.description"
                  maxlength="300"
                  :has-counter="false"
                ></b-input>
              </b-field>
            </b-field>

            <b-field :label="t('forms.tags')" label-position="on-border">
              <b-taginput
                v-model="imageEdit.tags"
                ellipsis
                icon="plus"
                :placeholder="t('common.add')"
                maxlength="300"
                type="is-primary"
                :aria-close-label="t('common.remove')"
              ></b-taginput>
            </b-field>

            <p class="has-text-centered">
              <b-field>
                <b-button
                class="mr-2"
                  @click="confirmDelete"
                  :loading="isLoading"
                  icon-left="delete"
                  expanded
                  type="is-danger"
                >
                  <span>{{t('multimedia.removeFile')}}</span>
                </b-button>

                <b-button
                  @click="saveFile"
                  :loading="isLoading"
                  icon-left="check"
                  expanded
                  type="is-primary"
                  :disabled="invalid"
                >
                  <span>{{t('common.saveChanges')}}</span>
                </b-button>
              </b-field>
            </p>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { ValidationObserver } from "vee-validate";
import { Action } from "../../store/config_actions";

export default {
  name: "ImageEdit",
  mixins: [CommonHelpers],
  components: { ValidationObserver },

  props: {
    imageId: { type: String, required: true },
  },

  data: function () {
    return {
      afmConfig: {},
      imageEdit: {},
      isLoading: false,
    };
  },

  mounted() {
    this.getFile();
    this.getAfmConfig();
  },

  watch: {},

  computed: {},

  methods: {
    /**
     * Get AFM config
     */
    getAfmConfig() {
      this.isLoading = true;
      if (this.$store.state.afmconfig.config) {
        this.afmConfig = this.$store.state.afmconfig.config;
        this.isLoading = false;
      } else {
        this.$store
          .dispatch(Action.AFM_CONFIG_GET)
          .then((payload) => {
            this.afmConfig = payload;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },

    /** save updatedFile */
    saveFile() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.MEDIA_UPDATE, this.imageEdit)
        .then((payload) => {
          this.imageEdit = payload;
          this.mSuccessSnack(this.t('common.changesSaved'));
          this.$emit("save");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: this.t('multimedia.removeFile'),
        message: this.t('multimedia.removeFileConfirm'),
        onConfirm: () => this.deleteFile(),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
    },

    /** delete file */
    deleteFile() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.MEDIA_DELETE, this.imageId)
        .then(() => {
          this.mSuccessSnack(this.t('common.fileRemoved'));
          this.$emit("save");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /** get actualFile */
    getFile() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.MEDIA_GET, this.imageId)
        .then((payload) => {
          this.imageEdit = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
